<template>
<v-card outlined @search="searchForPosts">
    <slot name="map" v-if="countriesLoaded" />
    <v-card-title :class="`mt-5 ${getTitleSize()}`" ref="posts">
        <v-divider class="ma-5" />
            <div v-if="typeof searchTerm !== 'undefined' && searchTerm !== ''">
                Posts for {{ searchName }}
            </div>
            <div v-else-if="'query' in $route.query">
                Posts for {{ $route.query.query }}
            </div>
            <div v-else>All Posts</div>
        <v-divider class="ma-5" />
    </v-card-title>
    <v-row justify="center" v-if="!('query' in $route.query)">
        <v-col cols="11" md="6">
            <CountrySelect v-if="countriesLoaded" @search="searchForPosts" />
        </v-col>
    </v-row>
    <v-card-text v-show="filteredPosts.length > 0">
        <zoom-center-transition group :duration="500" tag="div" :class="getBreakpointName() === 'xs' ? 'row' : 'row mx-4'">
            <v-col v-for="post in filteredPosts" :key="post.slug" cols="12" sm="6" lg="4" xl="3">
                <HoverCard>
                    <template v-slot:content>
                        <PostCard :post="post" />
                    </template>
                </HoverCard>
            </v-col>
        </zoom-center-transition>
    </v-card-text>
    <v-card-text v-show="filteredPosts.length === 0">
        <v-row justify="center">
            <v-col cols="11">
                <v-card outlined>
                    <v-card-text class="text-center title">No posts found.</v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-card-text>
</v-card>
</template>

<script>
import { ZoomCenterTransition } from "vue2-transitions";
import { mapGetters } from "vuex";
import { GET_POSTS, GET_VISITED_COUNTRIES } from "@/store/actions.type";

export default {
    name: "PostList",

    components: {
        HoverCard: () => import("@/components/HoverCard").then(m => m.default),
        CountrySelect: () => import("@/components/CountrySelect").then(m => m.default),
        PostCard: () => import("@/components/PostCard").then(m => m.default),
        ZoomCenterTransition
    },

    async mounted() {
        this.filteredPosts = await this.$store.dispatch(GET_POSTS);
        this.visitedCountries = await this.$store.dispatch(GET_VISITED_COUNTRIES);
        if (Object.keys(this.$route.query).length > 0) {
            this.dispatchSearch(this.$route.query);
        }
    },

    computed: {
        ...mapGetters(["findPosts", "posts", "countries", "countriesLoaded"])
    },

    data() {
        return {
            searchTerm: undefined,
            searchName: undefined,
            visitedCountries: [],
            filteredPosts: []
        };
    },

    methods: {
        dispatchSearch(searchQuery) {
            /*
            If we have a query in the url when landing on the page we check if it is a valid query.
            If 'country' is present we filter all posts by their coutry.
            If 'query' is present we filter all posts by their tags and title.
            All other query strings are discarded and all posts are returned.
            'Country' search has prevalence over the 'query' search.
            */
            if (searchQuery) {
                var type = '';
                var term = '';
                var name = '';
                if ('country' in searchQuery) {
                    var country = this.visitedCountries.find(el => el.id === searchQuery.country);
                    if (typeof country !== 'undefined') {
                        type = 'country';
                        term = country.id;
                        name = country.name;
                    }
                } else if ('query' in searchQuery) {
                    type = 'query';
                    term = searchQuery.query;
                    name = term;
                }
                this.searchForPosts(false, { type: type, term: term, name: name });
            }
        },
        async searchForPosts(scroll, query) {
            this.searchName = query.name;
            this.searchTerm = query.term;
            var searchQuery = {};
            if (this.searchTerm !== "") {
                searchQuery[query.type] = query.term;
            }
            if (JSON.stringify(this.$route.query) !== JSON.stringify(searchQuery)) {
                this.$router.replace({ name: this.$route.name, query: searchQuery }); // Do not navigate to route if already there
            }
            this.filteredPosts = this.findPosts(this.$route.query);

            if (scroll) {
                // Take into account the height of the header
                var offset = this.$refs.posts.getBoundingClientRect().top + window.pageYOffset - 64;
                window.scrollTo(0, offset);
            }
        }
    },

    watch: {
        '$route.query'(val) {
            this.dispatchSearch(val);
        }
    }
};
</script>

<style lang="scss">
</style>
